import { render, staticRenderFns } from "./BankForm.vue?vue&type=template&id=d1c9b4f8&scoped=true&"
import script from "./BankForm.vue?vue&type=script&lang=ts&"
export * from "./BankForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1c9b4f8",
  null
  
)

/* custom blocks */
import block0 from "./locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Cworks%5Cprojects%5Cinka_crm_vue%5Csrc%5Cviews%5Ccustomer%5Cbank%5CBankForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports