































































import {Component, Emit, Ref, Vue, Watch} from 'vue-property-decorator';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import {ActionResult} from '@/models/ActionResult';
import {BankAccount} from '@/models/bank/BankAccount';
import BankService from '@/views/customer/bank/BankService';
import i18n from "@/i18n";
import Suggestion from '@/components/suggestion/base-suggestion/Suggestion.vue';
import {SuggestionModel} from "@/components/suggestion/base-suggestion/SuggestionModel";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        ValidationProvider,
        ValidationObserver,
        Suggestion
    }
})
export default class BankForm extends Vue {
    @Ref('accountNumberElement') accountNumberElement!: any;

    isCreateAnother = false;
    bankAccount: BankAccount = new BankAccount();
    isLoading = false;
    isShow = false;
    isUpdate = false;
    listBank: SuggestionModel[] = [];
    listBranch: SuggestionModel[] = [];

    bankService: BankService;
    isLoadingBank = false;
    isLoadingBranch = false;
    errorMessage = '';
    disabled = false;

    constructor() {
        super();
        this.bankService = new BankService();
    }

    @Watch('isShow')
    onIsShowChange(value: boolean) {
        this.disabled = false;
        if (value) {
            this.bankSuggestion('');

            setTimeout(() => {
                this.accountNumberElement.$el.querySelector('input').focus();
            }, 100);
        }
    }

    @Watch('bankAccount.bankId', {deep: true})
    onChangeBankId(value: string, oldValue: string) {
        if (value) {
            this.branchSuggestion();
        } else {
            this.listBranch = [];
        }
    }

    @Emit('onNewBankAdded')
    onNewBankAccountAdded(bankAccountId: string) {
        return bankAccountId;
    }

    mounted() {
        // Get list bankAccount
        // this.bankService.getAllBanks()
        //     .then((rs: SearchResult<Suggestion>) => {
        //         // this.listBank = rs.items;
        //         // console.log(this.listBank);
        //     });
    }

    get popupTitle() {
        return this.bankAccount.bankId ? 'Cập nhật tài khoản' : 'Thêm tài khoản';
    }

    get listSelectedBank() {
        return this.bankAccount.bankId
            ? [{id: this.bankAccount.bankId, text: this.bankAccount.bankName}]
            : []
    }

    get listSelectedBranch() {
        return this.bankAccount.branchId
            ? [{id: this.bankAccount.branchId, text: this.bankAccount.branchName}]
            : []
    }

    @Emit('onBankAdded')
    onBankAdded(bankAccount: BankAccount) {
        return bankAccount;
    }

    @Emit('onBankUpdated')
    onBankUpdated(bankAccount: BankAccount) {
        return bankAccount;
    }

    onSelectBank(suggestions: SuggestionModel[]) {
        if (suggestions && suggestions.length > 0) {
            const firstSuggestion = suggestions[0];
            if (firstSuggestion) {
                this.bankAccount.bankId = firstSuggestion.id;
                this.bankAccount.bankName = firstSuggestion.text;
            }
        }
    }

    onSelectBranch(suggestions: SuggestionModel[]) {
        if (suggestions && suggestions.length > 0) {
            const firstSuggestion = suggestions[0];
            if (firstSuggestion) {
                this.bankAccount.branchId = firstSuggestion.id;
                this.bankAccount.branchName = firstSuggestion.text;
            }
        }
    }

    async onAddBank(searchTerm: string) {
        this.isLoadingBank = true;
        try {
            const result = await this.bankService.quickInsertBank(searchTerm);
            this.isLoadingBank = false;
            if (result.code > 0) {
                this.bankSuggestion(searchTerm);
            }
        } catch (e) {
            this.isLoadingBranch = false;
        }
    }

    async onAddBranch(searchTerm: string) {
        this.isLoadingBranch = true;
        try {
            const result = await this.bankService.quickInsertBranch(this.bankAccount.bankId, searchTerm);
            this.isLoadingBranch = false;
            if (result.code > 0) {
                this.branchSuggestion(searchTerm);
            }
        } catch (e) {
            this.isLoadingBranch = false;
        }
    }

    async onSearchBank(searchTerm: string) {
        this.bankSuggestion(searchTerm);
    }

    async onSearchBranch(searchTerm: string) {
        this.branchSuggestion(searchTerm);
    }

    onBankSelected(bankAccount: SuggestionModel) {
        this.bankAccount.bankName = bankAccount.text;
    }

    onBranchSelected(branch: SuggestionModel) {
        this.bankAccount.branchName = branch.text;
    }

    onAccountNumberBlur(e: any) {
        this.bankAccount.accountNumber = e.target.value;
        if (!this.bankAccount.accountNumber) {
            return;
        }
        this.bankService.findByAccountNumber(this.bankAccount.accountNumber)
            .then((result: ActionResult<BankAccount>) => {
                const bankAccount = result.data as BankAccount;
                if (result.data) {
                    this.bankAccount = bankAccount;
                    this.disabled = true;
                }

            })
            .catch(error => this.errorMessage = error.mesage);
    }

    add() {
        this.isShow = true;
        this.isUpdate = false;
        this.reset();
    }

    edit(bankAccount: BankAccount) {
        this.isShow = true;
        this.isUpdate = true;
        this.bankAccount = bankAccount;
    }

    save() {
        this.errorMessage = '';
        if (this.isUpdate) {
            this.showLoading();
            this.bankService.updateBankAccount(this.bankAccount)
                .then((result: ActionResult<string>) => {
                    this.showLoading(false);
                    if (result.code > 0) {
                        this.bankAccount.concurrencyStamp = result.data as string;
                        this.onBankUpdated(this.bankAccount);
                        this.isShow = false;
                        this.$vs.notify({
                            title: '',
                            text: result.message,
                            color: 'success'
                        });
                    }
                }, error => {
                    this.showLoading(false);
                    this.errorMessage = error.message;
                });
        } else {
            if (this.bankAccount.id) {
                this.onBankAdded(this.bankAccount);
                this.isShow = false;
            } else {
                this.showLoading();
                this.bankService.insertAccount(this.bankAccount.bankId, this.bankAccount)
                    .then((rs: ActionResult<BankAccount>) => {
                        this.onBankAdded(rs.data as BankAccount);
                        this.reset();
                        this.isShow = false;
                        this.showLoading(false);
                    }).catch(error => {
                    this.errorMessage = error.message;
                    this.showLoading(false);
                });
            }
        }
    }

    reset() {
        this.bankAccount = new BankAccount();
    }

    private async branchSuggestion(searchTerm = '') {
        this.isLoadingBranch = true;
        const result = await this.bankService.branchSuggestion(this.bankAccount.bankId, searchTerm);
        this.isLoadingBranch = false;
        if (result.code > 0) {
            this.listBranch = result.items.map((branch: any) => {
                return new SuggestionModel(branch.id, branch.name);
            })
        }

    }

    private async bankSuggestion(searchTerm: string) {
        this.isLoadingBank = true;
        const result = await this.bankService.bankSuggestion(searchTerm);
        this.isLoadingBank = false;
        if (result.code > 0) {
            this.listBank = result.items.map((bank: any) => {
                return new SuggestionModel(bank.id, bank.name);
            });
        }
    }

    private showLoading(isLoading: boolean = true) {
        if (isLoading) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }
}
