var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{attrs:{"classContent":"popup-example","title":_vm.popupTitle,"active":_vm.isShow},on:{"update:active":function($event){_vm.isShow=$event}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.errorMessage)?_c('vs-alert',{attrs:{"color":"warning","title":"Thông báo","active":"true"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('Account number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{ref:"accountNumberElement",staticClass:"w-full mt-5",attrs:{"placeholder":"Nhập số tài khoản","label":_vm.$t('Account number'),"danger":errors.length > 0,"dangerText":errors[0]},on:{"blur":_vm.onAccountNumberBlur},model:{value:(_vm.bankAccount.accountNumber),callback:function ($$v) {_vm.$set(_vm.bankAccount, "accountNumber", $$v)},expression:"bankAccount.accountNumber"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Beneficiary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-5",attrs:{"placeholder":"Nhập tên người thụ hưởng","label":_vm.$t('Beneficiary'),"danger":errors.length > 0,"dangerText":errors[0]},model:{value:(_vm.bankAccount.beneficiary),callback:function ($$v) {_vm.$set(_vm.bankAccount, "beneficiary", $$v)},expression:"bankAccount.beneficiary"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Bank name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Suggestion',{attrs:{"data":_vm.listBank,"label":"Ngân hàng","placeholder":'Nhập tên ngân hàng',"allow-add-when-not-exists":true,"list-selected":_vm.listSelectedBank},on:{"select":_vm.onSelectBank,"add":_vm.onAddBank,"searched":_vm.onSearchBank}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('Branch'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Suggestion',{attrs:{"data":_vm.listBranch,"label":"Chi nhánh","placeholder":'Nhập tên branch',"allow-add-when-not-exists":true,"list-selected":_vm.listSelectedBranch},on:{"add":_vm.onAddBranch,"select":_vm.onSelectBranch,"searched":_vm.onSearchBranch}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('vs-row',{staticClass:"mt-5"},[_c('vs-col',{staticClass:"text-right"},[_c('vs-button',{staticClass:"mr-2",attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){_vm.isShow = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }