export class BankAccount {
    id: string | undefined;
    bankId: string;
    bankName: string;
    accountNumber: string;
    beneficiary: string;
    branchId: string;
    branchName: string;
    concurrencyStamp: string | undefined;

    constructor() {
        this.bankId = '';
        this.bankName = '';
        this.accountNumber = '';
        this.branchId = '';
        this.branchName = '';
        this.beneficiary = '';
    }
}
